<template>
  <section class="footer container">

    <div class="grid">
     
      <div class="thirds">
        <AMARIcon class="icon"/>
        <h2 class="header1">Get<br/>in <span>touch</span></h2>
      </div>

      <div class="newsletter third span-sm-8 span-xs-6 vdouble vabottom">
        <Newsletter />
        <div class="grid disclaimer vcenter">
          <AMARLogo class="logo span4 span-xs-6"/>
          <p class="small span8">
            © {{ new Date().getFullYear() }} {{ settings?.data.copyright }} <br /> {{ settings?.data.address }}
          </p>
        </div>
      </div>

      <div class="span3 span-sm-4 span-xs-6">

        <h3 class="header5">General Enquiries</h3>
        <ul>
          <li v-if="settings.data.contact_email">
            <NuxtLink :to="`mailto:${settings.data.contact_email}`" target="_blank" class="bganim">
              {{ settings.data.contact_email }}
            </NuxtLink>
          </li>
          <li v-if="settings.data.contact_phone">
            <NuxtLink :to="`tel:${settings.data.contact_phone}`" target="_blank" class="bganim">
              {{ settings.data.contact_phone }}
            </NuxtLink>
          </li>
        </ul>
        
        <h3 class="header5">Follow Us @</h3>
        <ul>
          <li 
            v-for="social in settings?.data.social_media"
            :key="social.name"
          >
            <NuxtLink :to="social.link.url" target="_blank" class="bganim">
            {{ social.name }}
            </NuxtLink>
          </li>
        </ul>
      </div>

      <div class="span3 span-sm-4 span-xs-6">
        <h3 class="header5">Services</h3>
        <ul class="services">
          <li
            v-for="(service, index) in footer?.data.services"
            :key="`menu-service-${index}`"
          >
            <NuxtLink :to="`/services/${service.link.uid}`" class="bganim">
              {{ service.name }}
            </NuxtLink>
          </li>
        </ul>
      </div>
      
      <div class="span2 span-sm-4 span-xs-6">
        <h3 class="header5">Other Links</h3>
        <ul class="pages">
          <li
            v-for="(item, index) in footer?.data.pages"
            :key="`menu-item-${index}`"
          >
            <NuxtLink :to="`/${item.link.uid}`">
            {{ item.name }}
          </NuxtLink>
          </li>
        </ul>
      </div>

      

    </div>



  </section>
</template>


<script setup>
  import AMARIcon from '~/assets/svg/AMAR_Icon.svg?component'
  import AMARLogo from '~/assets/svg/AMAR_Logo.svg?component'

  const { client } = usePrismic();

  const { data: settings } = await useAsyncData("settings", () =>
    client.getSingle("settings")
  );
  const { data: footer } = await useAsyncData("footernavigation", () =>
    client.getSingle("footernavigation")
  );
</script>


<style lang="scss" scoped>
.footer {
  background: var(--color-white);
  color: var(--color-black);
  z-index: 0;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-top: -20px;
  .content {
    padding: 100px 0px;
    padding-top: 150px;
  }

  .icon {
    fill: var(--color-primary);
  }

  .logo {
    max-width: 150px;
    fill: var(--color-black) !important;
    margin: 0 auto;
  }

  .disclaimer {
    margin-top: 30px;
  }

  .small {
    font-size: 12px;
  }

  ul {
    font-size: 20px;
    line-height: 36px;
    list-style: none;
    text-transform: uppercase;
    padding: 0px;

    a {
      color: var(--color-black);
      text-decoration: none; 

      &:hover {
        color: var(--color-white);
      }

      &.router-link-active:hover {
        color: var(--color-black);
        cursor: default;
      }
    }

    &.services {
      font-size: 20px;
      line-height: 36px;
      margin-bottom: 30px;
    }

    &.pages {
      font-size: 18px;
      line-height: 24px;
      text-transform: none;

      a:hover {
        color: var(--color-primary);
      }
    }
  }

  ul + h3 {
    margin-top: 40px;
  }

  @media only screen and (max-width: 991px) {
    .newsletter {
      grid-row: 2 / span 2;
    }
  }
  @media only screen and (max-width: 767px) {
    .newsletter {
      grid-row: 6;
    }
  }
}
</style>
