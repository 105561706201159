<template>
	<div>
		<h3 class="header3">Subscribe<br />our Insights</h3>
		<p>We do not SPAM.</p>

		<div class="form">
			<form id="newsletter-subscribe" name="newsletter-subscribe" action="https://marketing.amardesign.co.uk/thank-you">
				<input type="email" name="email" class="required email" id="email" required value="" aria-label="Email"
					placeholder="Insert Email" autocomplete="off">
				<input type="submit" name="subscribe" id="subscribe" class="btn inverted" value="Subscribe"
					@mouseenter="soundStore.playHover" @click="subscribe">
			</form>
		</div>

	</div>
</template>

<script setup>
const gtm = useGtm()
const soundStore = useSoundStore()

const subscribe = () => {
	soundStore.playClick();

	gtm.trackEvent({
		event: 'interaction',
		category: 'Subscribe',
		action: 'click',
		label: 'User added to Mailing List'
	})
}

</script>

<style lang="scss">
input {
	height: 48px;
	border: 1px solid var(--color-lightgray);
	border-radius: var(--global-border-radius);
	background-color: var(--color-pure-white);
	outline: none;
	transition: all cubic-bezier(.35, 0, 0, 1) .5s, transform cubic-bezier(.35, 0, 0, 1) .4s;
	padding: 0px 20px;
	display: inline-block;

	&:focus {
		border: 1px solid var(--color-primary);
	}

	&:not(.btn) {
		min-width: 15vw;
	}
}

.form {
	margin: 30px 0px;
	display: inline-block;
}
</style>